import {
  makeStyles,
  RACCOLOR,
  RACTextConversationMessage,
  Typography,
} from '@rentacenter/racstrap';
import React, { useEffect, useRef } from 'react';
import clsx from 'clsx';
import {
  useTextConversation,
  useTextConversationActions,
} from '../../../context/TextConversationProvider/TextConversationProvider';
import { ApiStateWrapper } from '../../../common/ApiStateWrapper/ApiStateWrapper';
// import { useUserStateContext } from '../../../context/user/user-contexts';

export interface TextConversationContentProps {
  onComponentDidMount: () => void;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    padding: '1rem 0.5rem 1rem 0.5rem',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    width: '100%',
    // eslint-disable-next-line sonarjs/no-duplicate-string
    justifyContent: 'flex-start',
  },
  body: {
    width: '100%',
    overflowY: 'auto',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  noRecord: {
    display: 'flex',
    justifyContent: 'center !important',
  },
  headerItem: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    flex: 1,
  },
  headerItemValue: {
    color: `${RACCOLOR.INDEPENDENCE} !important`,
    whiteSpace: 'nowrap',
  },
  centered: {
    alignItems: 'center',
  },
  messageDate: {
    fontSize: `${theme.typography.pxToRem(12)} !important`,
    color: `${RACCOLOR.ARGENT} !important`,
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  leftMargin: {
    marginLeft: '2rem',
  },
}));
export const LongConversationContent = () => {
  const classes = useStyles();
  const ref = useRef(null);
  const { longMessages, isLongLoading, hasLongApiError } =
    useTextConversation();
  const { onRefreshLong } = useTextConversationActions();

  // const { user } = useUserStateContext();
  // const CoworkerName = `${user?.firstName} ${user?.lastName
  //   .charAt(0)
  //   .toUpperCase()}`;

  useEffect(() => {
    onRefreshLong();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ref?.current) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      ref?.current?.scroll({
        top: Number.MAX_SAFE_INTEGER,
        left: 0,
        behavior: 'smooth',
      });
    }
  }, [longMessages, isLongLoading]);

  return (
    <div className={classes.root}>
      <div
        className={clsx(
          classes.body,
          isLongLoading || !longMessages.length || hasLongApiError
            ? classes.noRecord
            : null
        )}
        ref={ref}
      >
        <ApiStateWrapper
          loading={isLongLoading}
          hasApiError={hasLongApiError}
          response={longMessages}
          successContent={
            <>
              {longMessages.map(
                ({
                  textMessageId,
                  isSentByRAC,
                  message,
                  messageDate,
                  initials,
                  tooltipTitle,
                  tooltipSubTitle,
                }) => (
                  <div key={textMessageId}>
                    <Typography
                      align="center"
                      variant="body1"
                      className={classes.messageDate}
                    >
                      {messageDate}
                    </Typography>
                    <RACTextConversationMessage
                      key={textMessageId}
                      isOwnMessage={isSentByRAC}
                      avatarText={initials}
                      message={message}
                      tooltipTitle={tooltipTitle}
                      tooltipSubtitle={tooltipSubTitle}
                    />
                  </div>
                )
              )}
            </>
          }
        />
      </div>
    </div>
  );
};
