import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { getCustomerPaymentSummary } from '../../api/payments';
import {
  Agreements,
  AgreementsSummary,
  Club,
  ClubSummary,
  CustomerLocationState,
  CustomerPaymentSummaryResponse,
  StoreDueSummary,
} from '../../types/types';
import { useLocation } from 'react-router-dom';
import { getSelectedStore, validateDateFormat } from '../../utils/utils';

export interface CustomerPaymentSummaryState {
  customerPaymentSummary: CustomerPaymentSummaryResponse | undefined;
  loading: boolean;
  hasApiError: boolean;
  agreementsSummary: Agreements[] | undefined;
  clubSummary: Club | undefined;
  selectedStoreDueSummary: StoreDueSummary | undefined;
}

export interface CustomerPaymentSummaryDispatchState {
  fetchCustomerPaymentSummary: (
    customerId: string,
    storeNumber: string
  ) => Promise<void | CustomerPaymentSummaryResponse>;
}

export const CustomerPaymentSummaryStateContext =
  createContext<CustomerPaymentSummaryState>({} as CustomerPaymentSummaryState);

export const CustomerPaymentSummaryDispatchContext =
  createContext<CustomerPaymentSummaryDispatchState>(
    {} as CustomerPaymentSummaryDispatchState
  );

export const CustomerPaymentSummaryProvider = (props: {
  children: ReactNode;
}) => {
  const [customerPaymentSummary, setCustomerPaymentSummary] =
    React.useState<CustomerPaymentSummaryResponse>();
  const [hasApiError, setHasApiError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [agreementsSummary, setAgreementsSummary] =
    React.useState<Agreements[]>();
  const [clubSummary, setClubSummary] = React.useState<Club>();
  const [selectedStoreDueSummary, setSelectedStoreDueSummary] =
    React.useState<StoreDueSummary>();
  const location = useLocation<CustomerLocationState>();

  const customerId =
    location?.state?.customer?.customerId || location?.pathname?.split('/')[3];

  const onLoad = async (customerId: string) => {
    const storeNumber = getSelectedStore();

    await fetchCustomerPaymentSummary(
      customerId,
      storeNumber == null ? '' : storeNumber
    );
  };

  useEffect(() => {
    onLoad(customerId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchCustomerPaymentSummary = async (
    customerId: string,
    storeNumber: string
  ) => {
    setHasApiError(false);
    setLoading(true);
    getCustomerPaymentSummary(customerId, storeNumber)
      .then((response) => {
        if (response?.agreementsSummary?.length) {
          const agreementsInfo = getAgreementsSummary(
            response?.agreementsSummary
          );

          setAgreementsSummary(agreementsInfo);
        }
        if (response.clubSummary?.clubId) {
          const ClubInfo = getClubSummary(response?.clubSummary);
          setClubSummary(ClubInfo);
        }
        if (response?.storeDueSummary?.length) {
          const selectedStoreSummary: StoreDueSummary[] =
            response.storeDueSummary.filter(
              (storeDue) => storeDue.storeNumber === storeNumber
            );
          setSelectedStoreDueSummary(selectedStoreSummary[0]);
        }
        setCustomerPaymentSummary(response);
      })
      .catch(() => setHasApiError(true))
      .finally(() => setLoading(false));
  };

  return (
    <CustomerPaymentSummaryStateContext.Provider
      value={{
        customerPaymentSummary,
        loading,
        hasApiError,
        agreementsSummary,
        clubSummary,
        selectedStoreDueSummary,
      }}
    >
      <CustomerPaymentSummaryDispatchContext.Provider
        value={{
          fetchCustomerPaymentSummary,
        }}
      >
        {props.children}
      </CustomerPaymentSummaryDispatchContext.Provider>
    </CustomerPaymentSummaryStateContext.Provider>
  );
};

export const getAgreementsSummary = (response: AgreementsSummary[]) => {
  return (
    response?.map((agreement) => ({
      agreementId: agreement.agreementId,
      amount: agreement.amountDue,
      nextDueDate: agreement?.nextDueDate
        ? validateDateFormat(agreement?.nextDueDate, 'YYYY-MM-DD')
          ? agreement?.nextDueDate
          : ''
        : '',
      currentDueDate: agreement?.currentDueDate,
    })) || []
  );
};

export const getClubSummary = (response: ClubSummary) => {
  return (
    {
      clubId: response?.clubId,
      amount: response?.amountDue,
      nextDueDate: response?.nextDueDate
        ? validateDateFormat(response?.nextDueDate, 'YYYY-MM-DD')
          ? response?.nextDueDate
          : ''
        : '',
      currentDueDate: response?.currentDueDate,
    } || null
  );
};

export const useCustomerPaymentSummary = () =>
  useContext(CustomerPaymentSummaryStateContext);

export const useCustomerPaymentSummaryActions = () =>
  useContext(CustomerPaymentSummaryDispatchContext);
