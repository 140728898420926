/* eslint-disable */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles, RACModalCard, Grid, Typography, RACButton, RACCheckBox, RACRadio, RACTextbox } from '@rentacenter/racstrap';
import { DapCalendarStyles } from "./DapCalendarStyles";

const useClasses = makeStyles((theme: any) => ({
  w100: {
    width: '100%',
  },
  card: {
    borderRadius: '16px!important',
    boxShadow: '0 1px 4px 0 rgba(0,0,0,.074)!important',
    width: '100%',
  },
  colMD12: {
    flex: '0 0 auto',
    width: '100%',
    paddingRight: '0.75rem',
    paddingLeft: '0.75rem',
  },
  reinforcementGrid: {
    marginLeft: '1.5rem'
  },
  floatStart: {
    float: 'left'
  },
  mb3: {
    marginBottom: '1rem'
  },
  formLabel: {
    marginBottom: '0.8rem !important',
    marginLeft: '0.4rem !important',
    color: '#111111',
    fontFamily: 'OpenSans-semibold',
    fontSize: '14px',
  },
  accordionHeader: {
    color: '#2179FE',
    fontFamily: 'OpenSans-bold',
    fontSize: '20px',
    marginBottom: '1.0rem !important',
  },
  headerLabel: {
    marginBottom: '1.0rem !important',
    color: '#111111',
    fontFamily: 'OpenSans-bold',
    fontSize: '16px',
  },
  formCheckInline: {
    display: 'inline-block',
    marginRight: '1rem',
  },
  formCheck: {
    minHeight: '1.5rem',
    marginBottom: '1.0rem',
  },
  checkboxLabel: {
    fontSize: '14px',
    display: 'inline-block',
    fontFamily: 'OpenSans-regular',
  },
  buttonsContent: {
    float: 'right',
    marginTop: '1rem'
  },
}));

interface SurveryFormProp {
  inputOpen: boolean;
  onClose(): void;
  customerDetails?: any;
  surveyDetails: any;
};

export function SurveyForm({ onClose, inputOpen, surveyDetails, customerDetails }: SurveryFormProp) {
  const classes: any = DapCalendarStyles();
  const customerSurveyClasses = useClasses();
  const history = useHistory();

  const addressFormatFnResFn = () => {
    return (customerDetails?.customerInfo?.address?.addressLine1 + ',' + customerDetails?.customerInfo?.address?.addressLine2 + ',' + customerDetails?.customerInfo?.address?.state)

  }
  const handleAddressClick = async () => {
    const addressFormatFnRes = await addressFormatFnResFn();
    const encodedAddress = encodeURIComponent(addressFormatFnRes);
    const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`;
    window.open(googleMapsURL, '_blank');
  };
  const handlePhoneNumberClick = () => {
    window.location.href = `tel:${customerDetails?.customerInfo?.phoneNumber}`;
  }
  const formatNum = (val: any) => {
    const cleaned = ('' + val).replace(/\D/g, '');
    const PhoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    return cleaned.replace(PhoneRegex, '($1) $2-$3');
  };

  const CustomerSurveyContent = () => {
    customerDetails
    return (
      <Grid item md={12}>
        <Grid item md={12}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.accordionHeader}`}>
            <Typography>
              Delivery Information
            </Typography>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <Typography>
              Agreement #
            </Typography>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <Typography>
              Customer Name
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }}>
            <a
              onClick={() => { history.push({ pathname: `/agreement/info/details/${customerDetails?.customerInfo?.customerId}/${customerDetails?.agreement?.agreementId}` }) }}
              className={classes.customerRaclink}
            >
              {customerDetails && customerDetails?.agreement?.agreementNumber}
            </a>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <a
              onClick={() => { history.push({ pathname: `/customer/update/${customerDetails?.customerInfo?.customerId}/customer` }) }}
              className={classes.customerRaclink}
            >
              {customerDetails?.customerInfo?.firstName + ` ` + customerDetails?.customerInfo?.lastName}
            </a>
          </Grid>
        </Grid>

        <Grid item md={12}
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }}>
            <Typography>
              Customer Address
            </Typography>
          </Grid>
          <Grid style={{ width: "50%" }}>
            <Typography>
              Phone #
            </Typography>
          </Grid>
        </Grid>

        <Grid
          className={classes.row} style={{ width: "100%", padding: "1px 14px" }}
        >
          <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
            <a
              onClick={() => { { customerDetails?.customerInfo?.address?.addressLine1 ? handleAddressClick() : '' } }}
            >
              {customerDetails && (customerDetails?.customerInfo?.address?.addressLine1|| '') + ' ' + customerDetails?.customerInfo?.address?.addressLine2 || ''}
              {customerDetails && (customerDetails?.customerInfo?.address?.city || '')}
            </a>
          </Grid>
          <Grid style={{ width: "50%" }} className={classes.customerRaclink}>
            <a
              onClick={() => { { customerDetails?.customerInfo?.phoneNumber ? handlePhoneNumberClick() : '' } }}
            >
              {customerDetails && (customerDetails?.customerInfo?.phoneNumber ? formatNum(customerDetails?.customerInfo?.phoneNumber) : '')}
            </a>
          </Grid>
        </Grid>
        <Grid style={{ margin: "10px", width: "100%" }}>
          <div style={{ width: '100%', float: 'left' }}>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.accordionHeader}`}>
                <Typography>
                Survey Form
                </Typography>
              </Grid>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.w100} ${customerSurveyClasses.headerLabel}`}>Questions</Typography>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>1. Are you 100% satisfied with the product and delivery? ( if not, solve immediately)<span style={{ "color": "red" }}> *</span></Typography>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    id="inlineRadio1"
                    defaultValue=''
                    value="yes"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => {
                    }}
                    checked={surveyDetails.productDeliveryQn === "yes"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>Yes</Typography>
                </Grid>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    defaultValue=''
                    id="inlineRadio2"
                    value="no"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => { }}
                    checked={surveyDetails.productDeliveryQn === "no"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>No</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>2. Have we answered all of your questions regarding the rental agreement?<span style={{ "color": "red" }}> *</span></Typography>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    defaultValue=''
                    value="yes"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    onClick={(e: any) => { }}
                    checked={surveyDetails.rentalAgrQn === "yes"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>Yes</Typography>
                </Grid>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    defaultValue=''
                    value="no"
                    disabled={surveyDetails.readOnlyMode}
                    size={'small'}
                    // onClick={(e: any) => {}}
                    checked={surveyDetails.rentalAgrQn === "no"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>No</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.w100} ${customerSurveyClasses.headerLabel}`}>Reinforcements</Typography>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}> 1. Timely Renewal Payments</Typography>
                <Grid className={`${customerSurveyClasses.reinforcementGrid}`}>
                  <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                    <RACCheckBox
                      checked={surveyDetails.renewalDateAndAmtQn}
                      size={'small'}
                      disabled={surveyDetails.readOnlyMode}
                      onClick={() => { }}
                    />
                    <Typography className={customerSurveyClasses.checkboxLabel}>1. Can you tell me when your next renewal is due and the amount you will pay?</Typography>
                  </Grid>
                  <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                    <RACCheckBox
                      checked={surveyDetails.autoEnrollQn}
                      size={'small'}
                      disabled={surveyDetails.readOnlyMode}
                      onClick={() => { }}
                    />
                    <Typography className={customerSurveyClasses.checkboxLabel}>2. AutoPay enrollment? If No explain to them the benefits & close the deal.</Typography>
                  </Grid>
                  <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                    <RACCheckBox
                      checked={surveyDetails.timelyPayments}
                      size={'small'}
                      disabled={surveyDetails.readOnlyMode}
                      onClick={() => { }}

                    />
                    <Typography className={customerSurveyClasses.checkboxLabel}>3. Reinforce the importance of timely payments and to contact the store <span style={{ color: '#2179FE', fontFamily: 'OpenSans-bold' }}>BEFORE payment is due </span> should you have any issue </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Grid className={`${customerSurveyClasses.colMD12} ${customerSurveyClasses.floatStart}`}>
                <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>2. Do you have any other questions or is there anything I can do for you?</Typography>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    defaultValue=''
                    value="yes"
                    size={'small'}
                    disabled={surveyDetails.readOnlyMode}
                    checked={surveyDetails.anyOtherQns === "yes"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>Yes</Typography>
                </Grid>
                <Grid className={`${customerSurveyClasses.formCheck} ${customerSurveyClasses.formCheckInline}`}>
                  <RACRadio
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    defaultValue=''
                    value="no"
                    size={'small'}
                    disabled={surveyDetails.readOnlyMode}
                    checked={surveyDetails.anyOtherQns === "no"}
                  />
                  <Typography className={customerSurveyClasses.checkboxLabel}>No</Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid className={`${customerSurveyClasses.w100} ${customerSurveyClasses.floatStart}`}>
              <Typography className={`${customerSurveyClasses.headerLabel} ${customerSurveyClasses.w100}`}>Notes
              </Typography>
              <Typography className={`${customerSurveyClasses.formLabel} ${customerSurveyClasses.w100}`}>Additional Comments
              </Typography>
              <RACTextbox
                maxlength={500}
                placeHolder="Enter the additional comments if you have anything"
                value={surveyDetails.additionalNotes}
                disabled={surveyDetails.readOnlyMode}
              />
            </Grid>
            <Grid
              item
              md={12}
              className={`${classes.pb4} ${classes.px3} ${classes.textRight} ${customerSurveyClasses.buttonsContent}`}
              style={{ marginTop: '100px' }}
            >
              <RACButton
                color="primary"
                variant="outlined"
                className={classes.mx1}
                onClick={onClose}
              >
                Cancel
              </RACButton>
              {surveyDetails.readOnlyMode ? null :
                <RACButton
                  variant="contained"
                  color="primary"
                  className={classes.mx1}
                  disabled={true}
                  onClick={() => { }}
                >
                  Save
                </RACButton>}
            </Grid>
          </div>
        </Grid>
      </Grid >
    )
  };

  return (
    <>
      <RACModalCard
        borderRadius="20px"
        isOpen={inputOpen}
        maxWidth="md"
        title="Customer Survey"
        closeIcon={true}
        onClose={onClose}
        // eslint-disable-next-line react/no-children-prop
        children={<>  <CustomerSurveyContent /> </>}
      />

    </>
  );
};