import { CancelToken } from 'axios';
import {
  StoreDetailResponse,
  StoreConfigsPayload,
  StoreConfigsResponse,
} from '../types/types';
import { client } from './client';
import { appConfig } from '../config/app-config';

export const getStoreDetailByNumber = (
  storeNumber: string,
  cancelToken?: CancelToken
): Promise<StoreDetailResponse> => {
  return client(`stores/${storeNumber}/details`, {
    method: 'GET',
    cancelToken,
  });
};

export const getStoreConfigs = (
  payload: StoreConfigsPayload,
  cancelToken?: CancelToken
): Promise<StoreConfigsResponse[]> => {
  return client(`/config/get-store-profile`, {
    method: 'POST',
    body: { ...payload },
    cancelToken,
  });
};
export const getSavedSurvey = (agreementId: any): Promise<any> => {
  return client(
    `store/survey/getagreement/${agreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.storemgmt,
    true
  );
};
export const getPaymentHistory = (
  customer_id: string,
  type: string,
  offset: any
): Promise<any> => {
  return client(
    `payment1/details/history/${customer_id}?type=${type}&limit=10&offset=${offset}`,
    { method: 'GET' },
    appConfig.apiUrls.payment,
    true
  );
};

export const getDeliveryAddr = (agreementId: any) => {
  return client(
    `agreement/delivery/info/${agreementId}`,
    { method: 'GET' },
    appConfig.apiUrls.agreement,
    true
  );
};
