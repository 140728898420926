/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import {
    RACButton,
    Grid,
    Typography,
    RACModalCard,
} from "@rentacenter/racstrap";
import { ReactComponent as TickIcon } from './../../../assets/img/TickIcon.svg';
import { useHistory } from "react-router-dom";
import { useClasses } from "./AgreementInfo";
import { postAgreementActivity } from "../../../api/Customer";
export default function RACExchange(props: any) {
    const classes = useClasses();
    const history = useHistory();
    const rentPaid = props?.value?.rentPaid;
    const suspenseAmount = props?.value?.suspenseAmount ? props?.value?.suspenseAmount : null;
    const [openSuspensePopup, setOpenSuspensePopup] = useState<boolean>(false);
    const [openExchangePopup, setOpenExchangePopup] = useState<boolean>(false);
    
    useEffect(()=> {
        if(suspenseAmount){
            setOpenSuspensePopup(true);
        }
        else{
            setOpenExchangePopup(true);
        }
    }, [])

    function showExchangePopup() {
        return (
            <Grid className={`${classes.textCenter}`}>
                {
                    !props.value.isExchangeFuncEnabled ?
                        <TickIcon/> : null
                }
                {props.value.isExchangeFuncEnabled ?
                    <>
                        <Typography className={`${classes.spacerMT3} ${classes.fontSize17p}`}>
                            Customer is eligible for ${rentPaid ? parseFloat(rentPaid)?.toFixed(2) : '0.00'} for Exchange.
                        </Typography>
                        <Typography className={`${classes.fontSize17p}`}>
                            Do you want to proceed with agreement creation?
                        </Typography>
                    </>
                    :
                    <Typography className={`${classes.fontSize17p} ${classes.spacerMT3}`}>
                        Please follow the new agreement creation workflow to complete the RAC Exchange Program.
                    </Typography>
                }
                <Grid className={`${classes.spacerMT32} ${classes.buttonsPadding}`}>
                    <RACButton
                        variant="outlined"
                        color="primary"
                        className={`${classes.floatLeft}`}
                        onClick={() => {
                            props.value.setExchangeNavigationPopup(false);
                        }}
                    >
                    {props.value.isExchangeFuncEnabled ? 'No' : 'Cancel'}
                    </RACButton>
                    {props.value.isExchangeFuncEnabled ?
                        <RACButton
                            variant="contained"
                            color="primary"
                            className={`${classes.floatRight}`}
                            onClick={() => {
                                postAgreementActivity({
                                    agreementActivities: [{
                                        agreementId: props?.value?.agreementId,
                                        activityDescription: `Agreement Exchange Selected`,
                                        agreementActivityType: "AGMXNGS",
                                    }]
                                })
                                history.push(`/agreement/rental/itemsearch/${props?.value?.customerId}?isExchange=1&agreementId=${props?.value?.agreementId}`);
                            }}
                        >
                            Create Agreement
                        </RACButton>
                        : null}
                </Grid>
            </Grid>
        )
    }

    function showSuspensePopup() {
        return(
            <Grid className={`${classes.textCenter}`}>
                <Typography className={`${classes.spacerMT3} ${classes.fontSize17p}`}>
                    The suspense amount of ${suspenseAmount ? suspenseAmount : 0.00 } on Agreement # {props?.value?.exchangeAgreementNumber ? props?.value?.exchangeAgreementNumber : props.value.agreementId } will be
                </Typography>
                <Typography className={`${classes.fontSize17p}`}>
                    transferred to the Exchange agreement after making the initial payment.
                </Typography>
                <Grid className={`${classes.spacerMT32} ${classes.centerButton}`}>
                    <RACButton
                        variant="contained"
                        color="primary"
                        className={`${classes.floatLeft}`}
                        onClick={() => {
                            setOpenExchangePopup(true);
                            setOpenSuspensePopup(false);
                        }}
                    >
                        Ok
                    </RACButton>
                </Grid>
            </Grid>
        );
    }
    
    return(
        <>
            <RACModalCard
                isOpen={openExchangePopup}
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                className={`${classes.customModal3}`}
                onClose={() => {
                    setOpenExchangePopup(false);
                    props.value.setExchangeNavigationPopup(false);
                }}
            >
                {showExchangePopup()}
            </RACModalCard>
            <RACModalCard
                isOpen={openSuspensePopup}
                closeIcon={true}
                maxWidth={'xs'}
                borderRadius={'25px !important'}
                className={`${classes.customModal4}`}
                onClose={() => {
                    setOpenSuspensePopup(false);
                    props.value.setExchangeNavigationPopup(false);
                }}
            >
                {showSuspensePopup()}
            </RACModalCard>
        </>
    );
}