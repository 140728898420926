import React from 'react';
import { RACModal, RACButton, makeStyles } from '@rentacenter/racstrap';

import { LongConversationContent } from './LongConversationContent';

const useStyles = makeStyles({
  dialogRoot: {
    '& .MuiDialog-paperScrollPaper': {
      height: 'calc(100% - 70px)',
    },
  },
});

export function LongConversation({ open, onClose }: any) {
  const classes = useStyles();
  // const { onRefresh, setTextConversationSelectedTab } =
  //   useTextConversationActions();

  return (
    <>
      <RACModal
        isOpen={open}
        titleVariant="h4"
        classes={{
          dialog: classes.dialogRoot,
        }}
        maxWidth="sm"
        title="Text Conversation"
        content={<LongConversationContent></LongConversationContent>}
        onClose={onClose}
        buttons={
          <>
            <RACButton variant="contained" color="primary" onClick={onClose}>
              Close
            </RACButton>
          </>
        }
      />
    </>
  );
}
