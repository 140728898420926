import { Typography, withStyles } from '@rentacenter/racstrap';
import clsx from 'clsx';
import React from 'react';
import { getNameForAbbreviation, StateAbbreviation } from '../../domain/Common';
import { PrintLetterDetails } from '../../types/types';
import halfwayletter_eng from './halfwayletterENG.json';
import halfwayletter_spa from './halfwayletterSPA.json';

import {
  formatDateString,
  formatPhoneNumberForPrintLetter,
} from '../../utils/utils';

export interface HalfwayNoticeLetterProps {
  printLetterDetails: PrintLetterDetails;
  classes?: any;
  t: any;
}

const useStyles = (theme: any) => ({
  allLettersContainer: {
    width: '100%',
  },
  breakPage: {
    breakBefore: 'page',
  },
  halfwayNoticeContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    padding: theme.typography.pxToRem(30),
    paddingLeft: theme.typography.pxToRem(80),
    background: 'white',
  },
  section: {
    paddingBottom: theme.typography.pxToRem(30),
  },
  paragraph: {
    paddingBottom: theme.typography.pxToRem(15),
  },
  headerMetadata: {
    width: theme.typography.pxToRem(400),
  },
  underline: {
    textDecoration: 'underline',
  },
  alignLeft: {
    textAlign: 'left',
  },
  lineHeight7: {
    lineHeight: theme.typography.pxToRem(7),
  },
  margin20: {
    marginBottom: theme.typography.pxToRem(20),
  },
});

const dateFormat = 'MM-dd-yyyy';

type LabelMap = {
  [key: string]: string;
};

const engMap: LabelMap = halfwayletter_eng;
const spaMap: LabelMap = halfwayletter_spa;

const t = (lang: string | undefined, title: string) => {
  if (lang === 'SPA') {
    return spaMap[title];
  }
  return engMap[title];
};

// This has to be class component, in order to integrate with react-to-print
class ComponentToPrint extends React.Component<HalfwayNoticeLetterProps> {
  render() {
    const {
      classes,
      printLetterDetails: { customer, store, dateOfNotice },
    } = this.props;
    return (
      <div className={classes.allLettersContainer}>
        {customer.map(
          (
            {
              firstName,
              lastName,
              lang,
              rentalAgreementExpiration,
              deadlineToReturnProperty,
              address: customerAddress,
            },
            index
          ) => {
            const { address, phoneNumber } = store;

            const storeAddressLines = [
              address.addressLine1,
              address.addressLine2,
            ]
              .filter(Boolean)
              .join(', ');
            const storeAddressDetails = [
              address.city,
              getNameForAbbreviation(address.state as StateAbbreviation),
              address.zipCode,
            ]
              .filter(Boolean)
              .join(', ');
            const formatedPhoneNumber =
              formatPhoneNumberForPrintLetter(phoneNumber) || phoneNumber;
            const customerName = `${firstName} ${lastName}`;
            const customerAddressLineDetails = [
              customerAddress.addressLine1,
              customerAddress.addressLine2,
            ]
              .filter(Boolean)
              .join(', ');
            const customerAddressDetails = [
              customerAddress.city,
              getNameForAbbreviation(
                customerAddress.state as StateAbbreviation
              ),
              customerAddress.zipCode,
            ]
              .filter(Boolean)
              .join(', ');

            return (
              <span key={index}>
                <div className={classes.halfwayNoticeContainer}>
                  <div className={clsx(classes.section, classes.alignLeft)}>
                    <Typography align="left" variant="h6">
                      Rent-A-Center, Inc.
                    </Typography>
                  </div>
                  <div
                    className={clsx(classes.section, classes.headerMetadata)}
                  >
                    <Typography
                      align="left"
                      variant="h6"
                      className={clsx(classes.paragraph, classes.lineHeight7)}
                    >
                      {storeAddressLines}
                    </Typography>
                    <Typography
                      align="left"
                      variant="h6"
                      className={clsx(classes.paragraph, classes.lineHeight7)}
                    >
                      {storeAddressDetails}
                    </Typography>
                    <Typography
                      align="left"
                      variant="h6"
                      className={clsx(
                        classes.paragraph,
                        classes.lineHeight7,
                        classes.margin20
                      )}
                    >
                      {formatedPhoneNumber}
                    </Typography>
                    <Typography
                      align="left"
                      variant="h6"
                      className={clsx(classes.paragraph, classes.lineHeight7)}
                    >
                      {customerName}
                    </Typography>
                    <Typography
                      align="left"
                      variant="h6"
                      className={clsx(classes.paragraph, classes.lineHeight7)}
                    >
                      {customerAddressLineDetails}
                    </Typography>
                    <Typography
                      align="left"
                      variant="h6"
                      className={clsx(classes.paragraph, classes.lineHeight7)}
                    >
                      {customerAddressDetails}
                    </Typography>
                  </div>
                  <div className={clsx(classes.section, classes.alignLeft)}>
                    <Typography
                      variant="h5"
                      align="center"
                      className={classes.paragraph}
                    >
                      {t(lang, 'title')}
                    </Typography>
                    <div>
                      <Typography align="left" display="inline" variant="h5">
                        {t(lang, 'dateExpired')}
                      </Typography>
                      <Typography
                        align="left"
                        display="inline"
                        variant="h6"
                        className={classes.underline}
                      >
                        {` ${formatDateString(
                          rentalAgreementExpiration.split('T')[0],
                          dateFormat
                        )}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography align="left" display="inline" variant="h5">
                        {t(lang, 'dateNotice')}
                      </Typography>
                      <Typography
                        align="left"
                        display="inline"
                        variant="h6"
                        className={classes.underline}
                      >
                        {` ${formatDateString(
                          dateOfNotice.split('T')[0],
                          dateFormat
                        )}`}
                      </Typography>
                    </div>
                    <div>
                      <Typography align="left" display="inline" variant="h5">
                        {t(lang, 'deadlineToReturn')}
                      </Typography>
                      <Typography
                        align="left"
                        display="inline"
                        variant="h6"
                        className={classes.underline}
                      >
                        {` ${formatDateString(
                          deadlineToReturnProperty.split('T')[0],
                          dateFormat
                        )} `}
                      </Typography>
                      <Typography align="left" display="inline" variant="h6">
                        {t(lang, 'deadlineToReturnMore')}
                      </Typography>
                    </div>
                    <div className={classes.paragraph}>
                      <Typography align="left" display="inline" variant="h6">
                        {t(lang, 'to')}
                      </Typography>
                      <Typography
                        align="left"
                        display="inline"
                        variant="h6"
                        className={classes.underline}
                      >
                        {` ${customerName}`}
                      </Typography>
                    </div>
                    <div className={classes.paragraph}>
                      <Typography align="left" variant="h6">
                        {t(lang, 'line1')}
                      </Typography>
                    </div>
                    <div className={classes.paragraph}>
                      <Typography align="left" variant="h6">
                        {t(lang, 'line2')}
                      </Typography>
                    </div>
                    <div className={classes.paragraph}>
                      <Typography align="left" variant="h6">
                        {t(lang, 'line3')}
                      </Typography>
                    </div>
                  </div>
                  <div className={classes.paragraph}>
                    <Typography align="left" variant="h6">
                      {t(lang, 'sincerely')}
                    </Typography>
                    <Typography
                      align="left"
                      variant="h6"
                      className={classes.underline}
                    >
                      {/* {storeManagerName} */}
                    </Typography>
                    <Typography align="left" variant="h6">
                      {t(lang, 'storeMgr')}
                    </Typography>
                  </div>
                </div>
                <div className={classes.breakPage}> </div>
              </span>
            );
          }
        )}
      </div>
    );
  }
}

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
export default withStyles(useStyles)(ComponentToPrint);
