/* eslint-disable sonarjs/no-identical-functions */

import {
  RACModal,
  RACButton,
  makeStyles,
  RACCOLOR,
  RACSelect,
  TextField,
  RACDatePicker,
  RACTimePicker,
  Typography,
} from '@rentacenter/racstrap';

import React, { useEffect, useState, useContext } from 'react';
import { orderByDisplaySeqField } from '../../../context/PastDueListSearchCriteria/PastDueListSearchCriteriaProvider';
import { getReference } from '../../../api/reference';
import { Agreements, Club, ReferenceKeys } from '../../../types/types';
import {
  getSelectedStore,
  mapReferenceResponse,
  pipe,
} from '../../../utils/utils';
import { API_ERROR_MESSAGE, CACHED_KEYS } from '../../../constants/constants';
import { Option } from '../../../types/types';
import clsx from 'clsx';
import { AppRoute } from '../../../config/route-config';
import { useUserStateContext } from '../../../context/user/user-contexts';
import {
  TakeCommitmentInput,
  CustomerLocationState,
} from '../../../types/types';
import { useLocation, useHistory } from 'react-router-dom';
import { takeCommitment, getCustomerAgreements } from '../../../api/Customer';
import { useCustomerPaymentSummary } from '../../../context/CustomerPaymentSummary/CustomerPaymentSummaryProvider';
import { CommitmentStateContext } from '../../../context/CommitmentProvider/CommitmentProvider';
import { CommitmentStateDispatchContext } from '../../../context/CommitmentProvider/CommitmentProvider';
import { useCustomerDetails } from '../../../context/CustomerDetails/CustomerDetailsProvider';
import { addSelectOneOption } from '../ContactInformation/LogActivityModal';
import { format } from 'date-fns';

export interface ModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => void;
  onError: () => void;
}
export const useStyles = makeStyles((theme: any) => ({
  dialogContent: {
    textAlign: 'left',
    height: theme.typography.pxToRem(400),
  },
  dialogRoot: {
    '& .MuiDialogContent-root': {
      padding: '1rem',
    },
    '& .MuiDialog-paperWidthSm': {
      maxWidth: theme.typography.pxToRem(800),
      maxHeight: theme.typography.pxToRem(407),
    },
    '& .MuiTypography-h4': {
      fontSize: '20px !important',
    },
    '& .MuiOutlinedInput-multiline': {
      padding: theme.typography.pxToRem(10),
    },
    '& .MuiInputBase-multiline': {
      margin: '8px 0px 0px 0px !important',
    },
    '& .MuiInputBase-input': {
      fontFamily: 'OpenSans-regular',
      fontSize: theme.typography.pxToRem(14),
    },
  },
  notesLabel: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
    position: 'relative',
    display: 'block',
  },
  amountLabel: {
    color: theme.palette.text.primary,
    transform: 'scale(1) !important',
    ...theme.typography.body1,
  },
  dialogActions: {
    paddingRight: theme.typography.pxToRem(15),
    paddingBottom: theme.typography.pxToRem(15),
  },
  notesWrapper: {
    display: 'inline-flex',
    position: 'relative',
    flexDirection: 'column',
    width: '100%',
    marginTop: theme.typography.pxToRem(24),
    marginBotton: theme.typography.pxToRem(16),
  },
  modalColumn: {
    width: '25%',
    paddingRight: theme.typography.pxToRem(12),
  },
  row: {
    display: 'flex',
  },
  topBottomLeftBorderRadius: {
    borderTopLeftRadius: `${theme.typography.pxToRem(9)} !important`,
    borderBottomLeftRadius: `${theme.typography.pxToRem(9)} !important`,
    borderBottomRightRadius: 0,
    borderTopRightRadius: 0,
  },
  topBottomRightBorderRadius: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: `${theme.typography.pxToRem(9)} !important`,
    borderTopRightRadius: `${theme.typography.pxToRem(9)} !important`,
  },
  amountValue: {
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(
      12
    )} ${theme.typography.pxToRem(6)} 0`,
    backgroundColor: RACCOLOR.ICEWIND_DALE,
    // Replace with SATIN_WHITE
    border: `${theme.typography.pxToRem(1)} solid ${RACCOLOR.ICEWIND_DALE}`,
    lineHeight: theme.typography.pxToRem(18),
    width: '90%',
    marginLeft: theme.typography.pxToRem(-1),
  },
  amountWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  amountValueWrapper: {
    marginTop: theme.typography.pxToRem(3),
    width: '100%',
    display: 'inline-flex',
  },
  amount$Value: {
    width: '10%',
    padding: `${theme.typography.pxToRem(6)} ${theme.typography.pxToRem(12)}`,
    backgroundColor: RACCOLOR.ICEWIND_DALE,
    // Replace with SATIN_WHITE
    border: `${theme.typography.pxToRem(1)} solid ${RACCOLOR.ICEWIND_DALE}`,
    lineHeight: theme.typography.pxToRem(18),
  },
}));

interface TakeCommitmentModalContentProps {
  validateForm: (state: boolean) => void;
  saveClicked: boolean;
  onSave: () => void;
  onError: () => void;
}
const NOTES_MAX_LENGTH = 150;
const TakeCommitmentModalContent = ({
  validateForm,
  saveClicked,
  onSave,
  onError,
}: TakeCommitmentModalContentProps) => {
  const classes = useStyles();
  const {
    selectedStoreDueSummary,
    agreementsSummary,
    clubSummary,
    customerPaymentSummary,
  } = useCustomerPaymentSummary();
  const {
    setChoosenCommitmentType,
    setCommitmentNotes,
    setCommitmentDate,
    setCommitmentTime,
  } = useContext(CommitmentStateDispatchContext);
  const [commitmentType, setCommitmentType] = useState('');
  const [commitmentTypeOption, setCommitmentTypeOption] = useState<Option[]>(
    []
  );
  const [apiError, setApiError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [time, setTime] = useState('');
  const [date, setDate] = useState('');
  const [note, setNote] = useState('');
  const [amount, setAmount] = useState('');
  const [agreementInfo, setAgreementInfo] = useState<Agreements[]>();
  const [clubInfo, setClubInfo] = useState<Club>();

  const dateFormat = 'yyyy-MM-dd';
  useEffect(() => {
    setIsLoading(true);
    setCommitmentNotes('');
    getReference(
      [ReferenceKeys.COMMITMENT_TYPE],
      CACHED_KEYS.TAKE_COMMITMENT_CACHED_KEY
    )
      .then((response: any) =>
        pipe(
          orderByDisplaySeqField,
          mapReferenceResponse,
          addSelectOneOption,
          setCommitmentTypeOption
        )(response?.references[0]?.referenceDetails)
      )
      .catch(() => {
        setApiError(true);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setAmount(location?.state?.commitmentDetails?.amount);
    } else {
      setAmount(selectedStoreDueSummary?.totalAmountDue || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useCustomerPaymentSummary]);

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setAgreementInfo(location?.state?.commitmentDetails?.agreements);
    } else {
      setAgreementInfo(agreementsSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agreementsSummary]);

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setClubInfo(location?.state?.commitmentDetails?.club);
    } else {
      setClubInfo(clubSummary);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubSummary]);

  useEffect(() => {
    validateForm(
      time === '' || date === '' || commitmentType === '' || note === ''
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveClicked, time, date, commitmentType, note]);

  useEffect(() => {
    if (saveClicked) {
      handleSave();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [saveClicked]);

  const { user } = useUserStateContext();
  const location = useLocation<CustomerLocationState>();

  useEffect(() => {
    if (location?.state?.commitmentDetails) {
      setChoosenCommitmentType(
        location?.state?.commitmentDetails?.commitmentType
      );
      setTime(location?.state?.commitmentDetails?.commitmentTime);
      setCommitmentTime(location?.state?.commitmentDetails?.commitmentTime);
      setDate(location?.state?.commitmentDetails?.commitmentDate);
      setCommitmentDate(location?.state?.commitmentDetails?.commitmentDate);
      setNote(location?.state?.commitmentDetails?.notes);
      setCommitmentNotes(location?.state?.commitmentDetails?.notes);
      setCommitmentType(location?.state?.commitmentDetails?.commitmentType);
      setAmount(location?.state?.commitmentDetails?.amount);
      setAgreementInfo(location?.state?.commitmentDetails?.agreements);
      setClubInfo(location?.state?.commitmentDetails?.club);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const handleSave = async () => {
    const customerId =
      location?.state?.customer?.customerId ||
      location?.pathname?.split('/')[3];
    const payload: TakeCommitmentInput = {
      storeNumber: getSelectedStore(),
      notes: note,
      customerId: customerId,
      coWorkerId: user?.employeeId || ' ',
      commitmentType: commitmentType,
      amount: amount,
      commitmentDate: `${date}T${time}`,
      agreements: agreementInfo,
      club: clubInfo,
    };

    const validateResult: any = validatePayloadWithSum(payload);

    let updatedPayload: any = validateResult.updatePayload;
    if (validateResult.notExist) {
      const custAgrs = await fetchCustomerAgreements(customerId);

      if (!custAgrs) return;
      updatedPayload = validatePayload(custAgrs, payload);
    }

    takeCommitment(updatedPayload)
      .then(() => onSave())
      .catch(() => onError());
  };
  const fetchCustomerAgreements = async (customerId: string) => {
    try {
      const response: any = await getCustomerAgreements(customerId);
      return response.agreement;
    } catch (e) {
      onError();
    }
  };

  const validatePayloadWithSum = (pload: TakeCommitmentInput) => {
    const sum: any = customerPaymentSummary || [];
    if (!pload.agreements) return { updatePayload: pload, notExist: false };
    let notExist = false;

    if (!sum || !sum.agreementsSummary) {
      return { updatePayload: pload, notExist: false };
    }
    const updatedAgreements = pload.agreements.filter((item: any) => {
      const findItem = sum.agreementsSummary.find(
        (element: any) =>
          element.agreementId == item.agreementId &&
          (element.status == 'ACTIVE' || element.status == 'PEND')
      );
      const existed = sum.agreementsSummary.find(
        (element: any) => element.agreementId == item.agreementId
      );
      if (!existed) notExist = true;
      return findItem;
    });
    if (updatedAgreements.length == pload.agreements.length)
      return { updatePayload: pload, notExist: false };
    const amountOrig: number = pload.agreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );
    const amountUpdate: number = updatedAgreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );

    const updatedAmount: number =
      Number(pload.amount).valueOf() - (amountOrig - amountUpdate);

    pload.agreements = updatedAgreements;
    pload.amount = updatedAmount.toFixed(2);
    return { updatePayload: pload, notExist: notExist };
  };

  const validatePayload = (customerAgrs: any, pload: TakeCommitmentInput) => {
    if (!pload.agreements) return pload;

    const updatedAgreements = pload.agreements.filter((item: any) => {
      return (
        !customerAgrs.find(
          (element: any) => element.agreementId == item.agreementId
        ) ||
        customerAgrs.find(
          (element: any) =>
            element.agreementId == item.agreementId &&
            (element.agreementRefCode == 'ACTIVE' ||
              element.agreementRefCode == 'PEND')
        )
      );
    });
    if (updatedAgreements.length == pload.agreements.length) return pload;
    const amountOrig: number = pload.agreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );
    const amountUpdate: number = updatedAgreements.reduce(
      (accumulator: number, item: any) => accumulator + Number(item.amount),
      0
    );

    const updatedAmount: number =
      Number(pload.amount).valueOf() - (amountOrig - amountUpdate);

    pload.agreements = updatedAgreements;
    pload.amount = updatedAmount.toFixed(2);
    return pload;
  };
  return (
    <>
      <div className={classes.row}>
        <div className={classes.modalColumn}>
          <RACSelect
            inputLabel="Commitment Type"
            defaultValue={commitmentType}
            options={commitmentTypeOption}
            loading={isLoading}
            onChange={(e: React.ChangeEvent<{ value: any }>) => {
              setCommitmentType(e.target.value);
              setChoosenCommitmentType(e.target.value);
            }}
            {...(apiError && {
              errorMessage: API_ERROR_MESSAGE,
            })}
          />
        </div>
        <div className={classes.modalColumn}>
          <RACDatePicker
            label="Date"
            inputProps={{ min: format(new Date(), dateFormat) }}
            value={date}
            onChange={(e: any) => {
              setDate(e);
              setCommitmentDate(e);
            }}
            name="commitmentDate"
          />
        </div>
        <div className={classes.modalColumn}>
          <RACTimePicker
            onChange={(e: any) => {
              setTime(e);
              setCommitmentTime(e);
            }}
            label="Time"
            name="commitmentTime"
            value={time}
          />
        </div>
        <div className={classes.modalColumn}>
          <label className={classes.amountLabel}>Amount</label>
          <div className={classes.amountValueWrapper}>
            <Typography
              display="inline"
              variant="body2"
              className={clsx(
                classes.amount$Value,
                classes.topBottomLeftBorderRadius
              )}
            >
              $
            </Typography>
            <Typography
              display="inline"
              variant="body2"
              className={clsx(
                classes.amountValue,
                classes.topBottomRightBorderRadius
              )}
            >
              {amount}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.notesWrapper}>
        <label className={classes.notesLabel}>Notes</label>
        <TextField
          multiline
          variant="outlined"
          value={note}
          key="logActivityNote"
          maxRows={4}
          minRows={47}
          onChange={(e: any) => {
            if (e.target.value.length > NOTES_MAX_LENGTH) {
              return;
            } else {
              setNote(e.target.value);
              setCommitmentNotes(e.target.value);
            }
          }}
        />
        <span>
          ({NOTES_MAX_LENGTH}/{note.length})
        </span>
      </div>
    </>
  );
};

export const TakeCommitmentModal = ({
  open,
  onSave,
  onClose,
  onError,
}: ModalProps) => {
  const classes = useStyles();
  const [isFormInvalid, setIsFormInvalid] = useState(true);
  const [isSaveClicked, setIsSaveClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const { customerDetails } = useCustomerDetails();
  const { customerId } = customerDetails || {};
  const {
    choosenCommitmentType,
    commitmentNotes,
    commitmentTime,
    commitmentDate,
  } = useContext(CommitmentStateContext);
  const history = useHistory();
  const handleSaveClick = () => {
    setIsSaveClicked(true);
    setLoading(true);
  };
  const handleCalculatePayClick = () => {
    history.push({
      pathname: `${AppRoute.TakePayment}/${customerId}/0`,
      search: `?origin=am-customer`,
      state: {
        commitmentData: {
          commitmentType: choosenCommitmentType,
          commitmentDate: commitmentDate,
          commitmentTime: commitmentTime,
          commitmentNotes: commitmentNotes,
        },
      },
    });
  };

  return (
    <RACModal
      isOpen={open}
      titleVariant="h4"
      classes={{
        dialogContent: classes.dialogContent,
        dialog: classes.dialogRoot,
        dialogActions: classes.dialogActions,
      }}
      maxWidth="sm"
      title="Take a Commitment"
      content={
        <TakeCommitmentModalContent
          validateForm={setIsFormInvalid}
          saveClicked={isSaveClicked}
          onSave={onSave}
          onError={onError}
        />
      }
      onClose={onClose}
      buttons={
        <>
          <RACButton
            variant="outlined"
            color="secondary"
            onClick={onClose}
            disabled={loading}
          >
            Cancel
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={handleCalculatePayClick}
            disabled={choosenCommitmentType == 'RT' ? true : false}
          >
            Calculate Payment
          </RACButton>
          <RACButton
            variant="contained"
            color="primary"
            onClick={handleSaveClick}
            disabled={isFormInvalid || loading}
            loading={loading}
          >
            Save
          </RACButton>
        </>
      }
    />
  );
};
