import { RACCOLOR } from '@rentacenter/racstrap';
import { CancelTokenSource } from 'axios';
import { isEqual, uniqWith } from 'lodash';
import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { getCancelTokenSource } from '../../api/client';
import {
  getCommunicationDetails,
  getCustomerDetails,
  getCustomerCommitment,
  getCustomerColorCode,
} from '../../api/Customer';
import {
  BADGE_RED_COLOR,
  CUSTOMER_TAB,
  DE_APPROVAL_STATUS,
  DE_APPROVAL_STATUS_TEXT,
  MESSAGE_STATUS,
  VERIFICATION_STATUS_TEXT,
} from '../../constants/constants';
import {
  CommunicationsDetails,
  CustomerApprovalDetailsResponse,
  CustomerDetailsResponse,
  CustomerInfoResponse,
  CustomerLocationState,
  CustomerInfoLabel,
  EmployerReference,
  PersonalReference,
  Phone,
  CustomerCommitmentResponse,
  CommitmentInfo,
  TextMessage,
} from '../../types/types';
import { useStoreDetails } from '../Store/StoreProvider';

// Helps for destructuring, so we don't have to add extra checks
const EmptyCustomerDetails = {
  addresses: [],
  employerReferences: [],
  personalReferences: [],
  landlordReferences: [],
  phones: [],
  coCustomerPhones: [],
};

export const EMPTY_DE_LABEL = {
  text: '',
  bgColor: '',
  showExpiryDate: false,
};

export const LANDLORD = 'Landlord';
const DAY_MILISECONDS = 24 * 3600 * 1000;

export interface CustomerDetailsState {
  customerDetails: Partial<CustomerDetailsResponse>;
  coCustomerDetails: Partial<CustomerDetailsResponse>;
  customerCommitment: Commitment | null;
  loading?: boolean;
  hasApiError: boolean;
  pastDueDate?: string;
  daysPastDue: string | number;
  amountDue?: string;
  loadingCoCustomer?: boolean;
  hasApiErrorCoCustomer: boolean;
  isCommunicationAllowed: boolean;
  isRefCommunicationAllowed: boolean;
  textConversationSelectedTab: string;
  customerApprovalDetailsResp: CustomerApprovalDetailsResponse | undefined;
  customerApprovalLabel: CustomerInfoLabel;
  customerVerificationLabel: CustomerInfoLabel;
  customerColorCodeDetails: any;
  getCustomerResponse: any;
  setgetCustomerResponse: any;
}

export interface CustomerDetailsDispatchState {
  fetchCustomerDetails: (
    customerId: string,
    cancelToken: CancelTokenSource
  ) => void;
  fetchCustomerColorCode: (customerId: string) => void;
  doFetchCommitmentForCustomer: () => void;
  fetchCommunicationDetailsForCustomer: (
    customerDetailsParam?: CustomerDetailsResponse,
    isCustomer?: boolean
  ) => Promise<void>;
  setTextConversationSelectedTab: (tab: string) => void;
  setLastMessage: (message: TextMessage | undefined) => void;
}
interface GenericObject {
  code: string;
  description: string;
}
export interface Commitment {
  commitmentDate: string;
  commitmentStatus: GenericObject;
  commitmentType: GenericObject;
  commitmentActualStatus: GenericObject;
  notes?: string;
  commitmentNotes?: string;
  commitmentAmount?: string;
}

export const CustomerDetailsStateContext = createContext<CustomerDetailsState>(
  {} as CustomerDetailsState
);
export const CustomerDetailsDispatchContext =
  createContext<CustomerDetailsDispatchState>(
    {} as CustomerDetailsDispatchState
  );

export const sortByPrimary = (phones: Phone[]) =>
  phones?.sort((phone) => {
    if (phone.primary === 'Y') return -1;
    return 1;
  });

export const mapCommunicationDetailsToPhones = (
  communicationsDetails: CommunicationsDetails,
  phones: any[],
  extraFields?: any
): any[] => {
  if (!communicationsDetails?.phoneCommunications?.length || !phones?.length)
    return phones || [];

  const { phoneCommunications } = communicationsDetails;
  const extendWithFields = extraFields ? extraFields : {};

  const mappedPhoneNumbers = phones?.map((phone: any) => {
    const communicationDetailsForPhone = phoneCommunications?.find(
      (communication) => communication.phoneNumber === phone.phoneNumber
    );

    return {
      ...phone,
      lastCallResultDescription:
        communicationDetailsForPhone?.lastCallResult?.description || '',
      communicationsToday:
        communicationDetailsForPhone?.communicationsToday || '0',
      communicationsPerYear:
        communicationDetailsForPhone?.communicationsThisYear || '0',
      ...extendWithFields,
    };
  });

  return sortByPrimary(mappedPhoneNumbers);
};

const commitTypes: any = {
  PY: 'Payment',
  RT: 'Return',
  CB: 'Call back',
  ML: 'mail',
  NS: 'Night slot',
  PO: 'Pay at other store',
};

/*
  Communication is not allowed when:
  - limit per day is reached
  - limit per year is reached
*/
export const checkIfLogActivityIsAllowed = (
  contactWeekLimit: number,
  communicationsDetails?: CommunicationsDetails
): boolean => {
  if (!communicationsDetails) return false;
  let { communicationsPerDayAllowed } = communicationsDetails;
  const { totalCommunicationsPastWeek } = communicationsDetails;
  const { totalCommunicationsToday } = communicationsDetails;
  /*
  To be removed once the service handles Massachusetts (MA) state issue
  if (
    communicationsPerYearAllowed &&
    totalCommunicationsThisYear >= communicationsPerYearAllowed
  )
    return false;
  */
  // temp fix since Limit per day is not set from service layer, to be removed
  if (!communicationsPerDayAllowed) communicationsPerDayAllowed = 3;
  if (totalCommunicationsToday >= communicationsPerDayAllowed) return false;
  if (
    totalCommunicationsPastWeek &&
    totalCommunicationsPastWeek >= contactWeekLimit
  )
    return false;

  return true;
};

/*
  Communication is not allowed when(same as for log activity):
  - limit per day is reached
  - limit per year is reached

  Exception:
  - limit is reached, but customer is the last one to reply
*/
export const checkIfCommunicationAllowed = (
  contactWeekLimit: number,
  communicationsDetails?: CommunicationsDetails,
  latestTextMessage?: TextMessage
): boolean => {
  if (
    latestTextMessage &&
    latestTextMessage.messageStatus === MESSAGE_STATUS.RECEIVED
  ) {
    return true;
  }

  return checkIfLogActivityIsAllowed(contactWeekLimit, communicationsDetails);
};

export const checkIfRefCommunicationAllowed = (
  referenceContactInterval: number,
  communicationsDetails?: CommunicationsDetails
): boolean => {
  if (!communicationsDetails) return false;
  const { lastRefCall } = communicationsDetails;
  if (!lastRefCall) return true;
  if (lastRefCall && lastRefCall.length === 0) return true;

  if (
    new Date().getTime() - new Date(lastRefCall[0].activity_date).getTime() >
    referenceContactInterval * DAY_MILISECONDS
  )
    return true;

  return false;
};

export const CustomerDetailsProvider = (props: { children: ReactNode }) => {
  const [customerDetails, setCustomerDetails] =
    useState<Partial<CustomerDetailsResponse>>(EmptyCustomerDetails);
  const [getCustomerResponse, setgetCustomerResponse] = useState<any>();

  const [customerColorCodeDetails, setCustomerColorCodeDetails] =
    useState<any>();

  const [customerApprovalDetailsResp, setCustomerApprovalDetails] =
    useState<CustomerApprovalDetailsResponse>();

  const [customerApprovalLabel, setCustomerApprovalLabel] =
    React.useState<CustomerInfoLabel>(EMPTY_DE_LABEL);

  const [customerVerificationLabel, setCustomerVerificationLabel] =
    React.useState<CustomerInfoLabel>(EMPTY_DE_LABEL);

  const [coCustomerDetails, setCoCustomerDetails] =
    useState<Partial<CustomerDetailsResponse>>(EmptyCustomerDetails);

  const [customerCommitment, setCustomerCommitment] =
    useState<Commitment | null>(null);
  const [loading, setLoading] = useState<boolean>();
  const [hasApiError, setHasApiError] = useState(false);
  const [loadingCoCustomer, setLoadingCoCustomer] = useState<boolean>();
  const [hasApiErrorCoCustomer, setHasApiErrorCoCustomer] = useState(false);

  const [pastDueDate, setPastDueDate] = useState('');
  const [daysPastDue, setDaysPastDue] = useState<string | number>('');
  // TODO: connect amount due
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [amountDue, setAmountDue] = useState('');
  const [isCommunicationAllowed, setIsCommunicationAllowed] =
    useState<boolean>(false);
  const [isRefCommunicationAllowed, setIsRefCommunicationAllowed] =
    useState<boolean>(true);

  const [communicationDetailsCustomer, setCommunicationDetailsCustomer] =
    useState<CommunicationsDetails>();

  const [lastMessage, setLastMessage] = useState<TextMessage | undefined>();
  const { contactWeekLimit } = useStoreDetails();
  const { referenceContactInterval } = useStoreDetails();

  /*
  selectedTab has been moved from TextConversationProvider to CustomerDetailsProvider,
  in order to avoid dependency cycle.
  */
  const [textConversationSelectedTab, setTextConversationSelectedTab] =
    useState<string>(CUSTOMER_TAB);

  const location = useLocation<CustomerLocationState>();

  const setStateValuesFromLocation = () => {
    if (location?.state?.customer) {
      const { daysPastDue, pastDueDate } = location.state.customer;

      setPastDueDate(pastDueDate);
      setDaysPastDue(daysPastDue);
    }
  };

  useEffect(() => {
    setIsCommunicationAllowed(
      checkIfCommunicationAllowed(
        contactWeekLimit,
        communicationDetailsCustomer,
        lastMessage
      )
    );
    setIsRefCommunicationAllowed(
      checkIfRefCommunicationAllowed(
        referenceContactInterval,
        communicationDetailsCustomer
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lastMessage, communicationDetailsCustomer]);

  useEffect(() => {
    if (!communicationDetailsCustomer || !coCustomerDetails?.customerId) return;

    const phonesWithCommunicationDetails = mapCommunicationDetailsToPhones(
      communicationDetailsCustomer,
      coCustomerDetails.phones || []
    );

    setCoCustomerDetails({
      ...coCustomerDetails,
      phones: phonesWithCommunicationDetails,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [communicationDetailsCustomer]);

  useEffect(() => {
    const customerId = location?.state?.customer?.customerId;

    const urlCustomerId = location?.pathname?.split('/')[3];

    if (!customerId && !urlCustomerId) return;
    setIsCommunicationAllowed(false);
    const cancelToken: CancelTokenSource = getCancelTokenSource();
    fetchCustomerDetails(customerId || urlCustomerId, cancelToken);
    setStateValuesFromLocation();
    return () => {
      cancelToken.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  /*
    Stores the response into state (communicationDetailsCustomer)
    and extracts communication details by phone for customer, reference and empoyer.
    The communication details for coCustomer are handled in a useEffect.
  */
  const fetchCommunicationDetailsForCustomer = async (
    customerDetailsParam?: CustomerDetailsResponse,
    isCustomer?: boolean
  ) => {
    try {
      const customerDetailsToUse = customerDetailsParam
        ? customerDetailsParam
        : isCustomer
        ? customerDetails
        : coCustomerDetails;

      if (!customerDetailsToUse?.customerId) return;
      const response = await getCommunicationDetails(
        customerDetailsToUse?.customerId
      );
      const {
        communicationsPerDayAllowed,
        communicationsPerYearAllowed,
        totalCommunicationsThisYear,
        totalCommunicationsToday,
        totalCommunicationsPastWeek,
        lastRefCall,
        phoneCommunications,
      } = response;
      const communicationDetailsWithNumbers = {
        ...response,
        communicationsPerDayAllowed: Number(communicationsPerDayAllowed),
        communicationsPerYearAllowed: Number(communicationsPerYearAllowed),
        totalCommunicationsThisYear: Number(totalCommunicationsThisYear),
        totalCommunicationsToday: Number(totalCommunicationsToday),
        totalCommunicationsPastWeek: Number(totalCommunicationsPastWeek),
        lastRefCall: lastRefCall,
        phoneCommunications: phoneCommunications,
      };
      setCommunicationDetailsCustomer(communicationDetailsWithNumbers);

      const phonesWithCommunicationDetails = mapCommunicationDetailsToPhones(
        communicationDetailsWithNumbers,
        customerDetailsToUse?.phones || []
      ) as Phone[];

      let personalReferencesWithCommunicationDetails =
        mapCommunicationDetailsToPhones(
          communicationDetailsWithNumbers,
          customerDetailsToUse?.personalReferences || []
        ) as PersonalReference[];

      /*
      Landlord and personal references are mapped into one array:
        personalReferences: [
          ...personalReferencesWithCommunicationDetails,
          ...landLordReferencesWithCommunicationDetails,
        ],
        Which under certain circumstances leads to duplicate values.
        (e.g. when this function - fetchCommunicationDetailsForCustomer - is rerun).
        In order to avoid this situation, we are filtering out those reference objects which do not have
        personalReferenceId. 
       */
      personalReferencesWithCommunicationDetails =
        personalReferencesWithCommunicationDetails.filter(
          (reference) => reference.personalReferenceId
        );
      const landLordReferencesWithCommunicationDetails =
        mapCommunicationDetailsToPhones(
          communicationDetailsWithNumbers,
          customerDetailsToUse?.landlordReferences || [],
          {
            relationshipTypeDesc: LANDLORD,
          }
        ) as PersonalReference[];

      const employerWithCommunicationDetails = mapCommunicationDetailsToPhones(
        communicationDetailsWithNumbers,
        customerDetailsToUse.employerReferences || []
      ) as EmployerReference[];

      const details = {
        ...customerDetailsToUse,
        phones: phonesWithCommunicationDetails,
        personalReferences: [
          ...personalReferencesWithCommunicationDetails,
          ...landLordReferencesWithCommunicationDetails,
        ],
        employerReferences: employerWithCommunicationDetails,
      };

      if (isCustomer) {
        setCustomerDetails(details);
      } else {
        const coCustomerdetails: any = {
          ...customerDetailsToUse,
          phones: uniqWith(
            [
              ...phonesWithCommunicationDetails,
              ...personalReferencesWithCommunicationDetails,
            ],
            isEqual
          ),
          personalReferences: personalReferencesWithCommunicationDetails,
          employerReferences: employerWithCommunicationDetails,
        };
        setCoCustomerDetails(coCustomerdetails);
      }
    } catch {
      setIsCommunicationAllowed(false);
    }
  };

  const fetchCustomerApprovalDetails = async (
    customerId: string,
    cancelToken: CancelTokenSource
  ) => {
    setHasApiError(false);
    setLoading(true);
    getCustomerDetails(customerId, cancelToken.token)
      .then(async (responseToUse: CustomerInfoResponse) => {
        setLoading(false);
        setgetCustomerResponse(responseToUse);
        setCustomerApprovalDetails(responseToUse.GetApproval.value);
        const customerApprovalDecisionInfo = getDELabel(
          responseToUse.GetApproval.value
        );
        setCustomerApprovalLabel(customerApprovalDecisionInfo);
        const customerVerificationInfo = getCustomerVerificationLabel(
          responseToUse.GetCustomer.value
        );
        setCustomerVerificationLabel(customerVerificationInfo);
      })
      .catch(() => setHasApiError(true))
      .finally(() => setLoading(false));
  };

  const fetchCustomerColorCode = async (customerId: string) => {
    try {
      const colorCodeResponse = await getCustomerColorCode({
        customerId: customerId,
      });
      setCustomerColorCodeDetails(colorCodeResponse);
    } catch {
      setCustomerColorCodeDetails(undefined);
    }
  };

  const fetchCustomerDetails = (
    customerId: string,
    cancelToken: CancelTokenSource
  ) => {
    setHasApiError(false);
    setLoading(true);
    setCustomerDetails(EmptyCustomerDetails);
    setCoCustomerDetails(EmptyCustomerDetails);
    getCustomerDetails(customerId, cancelToken.token)
      .then(async (customerInfoResponse: CustomerInfoResponse) => {
        const response = customerInfoResponse.GetCustomer.value;
        setgetCustomerResponse(response);

        response.employerReferences =
          response.employerReferences?.map((employerRef) => ({
            ...employerRef,
            phoneNumber: employerRef.employerPhoneNumber,
          })) || [];
        setCustomerDetails(response);
        if (response.coCustomerId) {
          await fetchCoCustomerDetails(response.coCustomerId, cancelToken);
        } else if (response.coCustomer === 'Y' && response.primaryCustomerId) {
          await fetchCoCustomerDetails(response.primaryCustomerId, cancelToken);
        }

        await fetchCustomerColorCode(response.globalCustomerId);
        await fetchCustomerApprovalDetails(response.customerId, cancelToken);
        await fetchCommunicationDetailsForCustomer(response, true);
        await fetchCommitmentForCustomer(response.customerId, cancelToken);
      })
      .catch(() => !cancelToken.token.reason && setHasApiError(true))
      .finally(() => !cancelToken.token.reason && setLoading(false));
  };

  const fetchCoCustomerDetails = (
    customerId: string,
    cancelToken: CancelTokenSource
  ) => {
    setCoCustomerDetails(EmptyCustomerDetails);
    setHasApiErrorCoCustomer(false);
    setLoadingCoCustomer(true);
    getCustomerDetails(customerId, cancelToken.token)
      .then(async (customerInfoResponse: CustomerInfoResponse) => {
        const response = customerInfoResponse.GetCustomer.value;
        setgetCustomerResponse(response);
        setCoCustomerDetails(response);
        await fetchCommunicationDetailsForCustomer(response, false);
      })
      .catch(() => !cancelToken.token.reason && setHasApiErrorCoCustomer(true))
      .finally(() => !cancelToken.token.reason && setLoadingCoCustomer(false));
  };

  const doFetchCommitmentForCustomer = () => {
    const customerId = location?.state?.customer?.customerId;

    const urlCustomerId = location?.pathname?.split('/')[3];

    if (!customerId && !urlCustomerId) return;
    const cancelToken: CancelTokenSource = getCancelTokenSource();
    fetchCommitmentForCustomer(customerId || urlCustomerId, cancelToken);
  };
  const fetchCommitmentForCustomer = (
    customerId: string,
    cancelToken: CancelTokenSource
  ) => {
    setCustomerCommitment(null);
    setHasApiError(false);
    setLoading(true);
    getCustomerCommitment(customerId, cancelToken.token)
      .then((commitmentResponse: CustomerCommitmentResponse) => {
        const commit = commitmentResponse.commitment as CommitmentInfo;

        setCustomerCommitment({
          commitmentDate: commit.commitmentDate,
          commitmentStatus: {
            code: commit.commitmentStatusCode,
            description: '',
          },
          commitmentType: {
            code: commit.commitmentTypeCode,
            description: commitTypes[commit.commitmentTypeCode],
          },
          commitmentActualStatus: {
            code: commit.commitmentStatusCode,
            description: '',
          },
          notes: commit.commitmentNotes,
          commitmentNotes: commit.commitmentNotes,
          commitmentAmount: commit.commitmentAmount,
        });
      })
      .catch(() => !cancelToken.token.reason && setHasApiError(true))
      .finally(() => !cancelToken.token.reason && setLoading(false));
  };

  return (
    <CustomerDetailsStateContext.Provider
      value={{
        customerDetails,
        coCustomerDetails,
        customerCommitment,
        loading,
        hasApiError,
        pastDueDate,
        daysPastDue,
        amountDue,
        loadingCoCustomer,
        hasApiErrorCoCustomer,
        isCommunicationAllowed,
        isRefCommunicationAllowed,
        textConversationSelectedTab,
        customerApprovalDetailsResp,
        customerApprovalLabel,
        customerVerificationLabel,
        customerColorCodeDetails,
        getCustomerResponse,
        setgetCustomerResponse,
      }}
    >
      <CustomerDetailsDispatchContext.Provider
        value={{
          fetchCustomerDetails,
          fetchCommunicationDetailsForCustomer,
          setTextConversationSelectedTab,
          doFetchCommitmentForCustomer,
          setLastMessage,
          fetchCustomerColorCode,
        }}
      >
        {props.children}
      </CustomerDetailsDispatchContext.Provider>
    </CustomerDetailsStateContext.Provider>
  );
};

export const useCustomerDetails = () => useContext(CustomerDetailsStateContext);

export const useCustomerDetailsActions = () =>
  useContext(CustomerDetailsDispatchContext);

export const getDELabel = (responseToUse: CustomerApprovalDetailsResponse) => {
  const deLabel = {
    text: '',
    bgColor: '',
    showExpiryDate: false,
  };
  if (responseToUse && responseToUse?.customerApprovalDetails?.approvalStatus) {
    if (
      responseToUse?.customerApprovalDetails?.approvalStatus ==
      DE_APPROVAL_STATUS.APPROVED
    ) {
      deLabel.text = DE_APPROVAL_STATUS_TEXT.APPROVED;
      deLabel.bgColor = RACCOLOR.VITAL_GREEN;
      deLabel.showExpiryDate = true;
    } else if (
      responseToUse?.customerApprovalDetails?.approvalStatus ==
      DE_APPROVAL_STATUS.CONDITIONAL_APPROVAL
    ) {
      deLabel.text = DE_APPROVAL_STATUS_TEXT.CONDITIONAL_APPROVAL;
      deLabel.bgColor = RACCOLOR.MARIGOLD;
      deLabel.showExpiryDate = true;
    } else if (
      responseToUse?.customerApprovalDetails?.approvalStatus ==
      DE_APPROVAL_STATUS.DECLINED
    ) {
      deLabel.text = DE_APPROVAL_STATUS_TEXT.DECLINED;
      deLabel.bgColor = BADGE_RED_COLOR;
    } else if (
      responseToUse?.customerApprovalDetails?.approvalStatus ==
      DE_APPROVAL_STATUS.EXPIRED
    ) {
      deLabel.text = DE_APPROVAL_STATUS_TEXT.EXPIRED;
      deLabel.bgColor = BADGE_RED_COLOR;
    } else {
      deLabel.text = DE_APPROVAL_STATUS_TEXT.NO_DE;
      deLabel.bgColor = BADGE_RED_COLOR;
    }
  } else {
    deLabel.text = DE_APPROVAL_STATUS_TEXT.NO_DE;
    deLabel.bgColor = BADGE_RED_COLOR;
  }
  return deLabel;
};

export const getCustomerVerificationLabel = (
  response: CustomerDetailsResponse
) => {
  const verificationLabel = {
    text: '',
    bgColor: '',
    showExpiryDate: false,
  };
  if (
    response &&
    response?.verified &&
    response?.verified?.toUpperCase() === 'Y' &&
    response?.verifiedDate
  ) {
    verificationLabel.text = VERIFICATION_STATUS_TEXT.VERIFIED;
    verificationLabel.bgColor = RACCOLOR.VITAL_GREEN;
    verificationLabel.showExpiryDate = true;
  } else {
    verificationLabel.text = VERIFICATION_STATUS_TEXT.VERIFICATIONREQUIRED;
    verificationLabel.bgColor = BADGE_RED_COLOR;
    verificationLabel.showExpiryDate = false;
  }
  return verificationLabel;
};
