import React, { createContext, useState } from 'react';
export const CommitmentStateContext = createContext<any>('');
export const CommitmentStateDispatchContext = createContext<any>('');
export default function CommitmentProvider(props: any) {
  const [choosenCommitmentType, setChoosenCommitmentType] = useState('');
  const [commitmentNotes, setCommitmentNotes] = useState('');
  const [commitmentDate, setCommitmentDate] = useState('');
  const [commitmentTime, setCommitmentTime] = useState('');
  return (
    <CommitmentStateContext.Provider
      value={{
        choosenCommitmentType,
        commitmentNotes,
        commitmentDate,
        commitmentTime,
      }}
    >
      <CommitmentStateDispatchContext.Provider
        value={{
          setChoosenCommitmentType,
          setCommitmentNotes,
          setCommitmentDate,
          setCommitmentTime,
        }}
      >
        {props.children}
      </CommitmentStateDispatchContext.Provider>
    </CommitmentStateContext.Provider>
  );
}
