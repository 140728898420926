import { CancelToken } from 'axios';
import { appConfig } from '../config/app-config';
import { FilterPayload } from '../domain/PastDueList/Filter';
import { TextTemplate } from '../domain/TextConversation/TextTemplate';
import { LogWorkedHistoryPayload } from '../domain/WorkedHistory/WorkedHistory';
import {
  ActivityPayload,
  TakeCommitmentInput,
  AgreementInfoResponse,
  AssignRoutePayload,
  FieldSheetResponse,
  GetCustomerFieldSheetsPayload,
  StoreRouteResponse,
  WorkedHistoryResponse,
  CustomerCommitmentResponse,
  CommunicationsDetailsResponse,
  CustomerPhoneInstructionPayload,
  CustomerInfoResponse,
} from '../types/types';
import { getSelectedStore } from '../utils/utils';
import { client } from './client';

const storeNumber = getSelectedStore();

export const getRetentionOffers = async (Payload: any) => {
  return await client(
    `retention/offers`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.customer
  );
};

export const GetRemoteLoginStatus = async (Payload: any) => {
  return await client(
    `cashmgmt/duo/auth`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.cashmgmt
  );
};

export const getCustomerColorCode = async (Payload: any) => {
  return await client(
    `customer/color/coding`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.customer
  );
};

export const postAgreementActivity = async (Payload: any) => {
  return await client(
    `agreement/activity`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.customer
  );
};

export const getPinDetails = async (Payload: any) => {
  return await client(
    `coworker`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.coworker
  );
};

export const getprofileaccess = async (Payload: any) => {
  return await client(
    `store-access`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.coworker
  );
};

export const getsecondfactor = async (Payload: any) => {
  return await client(
    `coworker-authentication`,
    { method: 'POST', body: Payload },
    appConfig.apiUrls.coworker
  );
};

export const getPastDueList = async (
  payload: FilterPayload,
  offset: number,
  limit: number,
  cancelToken?: CancelToken
) =>
  client(`past-due-list?limit=${limit}&offset=${offset}`, {
    method: 'POST',
    body: payload,
    cancelToken,
  });

export const getCustomerDetails = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customerId: string,
  cancelToken?: CancelToken
): Promise<CustomerInfoResponse> => {
  return client(
    `customer/${customerId}`,
    {
      method: 'PUT',
      body: {
        storeNumber: storeNumber,
      },
      cancelToken,
    },
    appConfig.apiUrls.customer
  );
};

export const getCustomerCommitment = (
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customerId: string,
  cancelToken?: CancelToken
): Promise<CustomerCommitmentResponse> => {
  return client(
    `customers/${customerId}/details?oper=commitment&storeNumber=${storeNumber}`,
    {
      method: 'GET',
      cancelToken,
    }
  );
};

export const getCustomerAgreements = (
  customerId: string,
  cancelToken?: CancelToken
): Promise<AgreementInfoResponse> =>
  client(
    `customer/${customerId}/agreements?inActive=N`,
    {
      method: 'GET',
      cancelToken,
    },
    appConfig.apiUrls.customer
  );

export const getWorkedHistory = (
  storeNumber: string,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  customerId: string,
  cancelToken: CancelToken
): Promise<WorkedHistoryResponse> =>
  client(`stores/${storeNumber}/customers/${customerId}/work-history`, {
    method: 'GET',
    cancelToken,
  });

export const getStoreRoutes = (
  storeNumber: string,
  cancelToken?: CancelToken
): Promise<StoreRouteResponse> =>
  client(`store-routes/${storeNumber}`, {
    method: 'GET',
    cancelToken,
  });

export const logWorkedHistory = (
  payload: Partial<LogWorkedHistoryPayload>
): Promise<WorkedHistoryResponse> =>
  client('customer/logactivity', {
    method: 'POST',
    body: payload,
  });

export const getAgreementInfo = (
  customerId: string,
  cancelToken?: CancelToken
): Promise<AgreementInfoResponse> =>
  client(`customer/get-agr-pastdue/${customerId}`, {
    method: 'GET',
    cancelToken,
  });

export const getTextTemplates = (): Promise<TextTemplate[]> =>
  client('/text-message/get-text-templates', { method: 'GET' });

export const logActivity = (payload: ActivityPayload) =>
  client('/customer/logactivity', { method: 'POST', body: { ...payload } });

export const assignRoute = (payload: AssignRoutePayload) =>
  client('/customer/assignroute', { method: 'POST', body: { ...payload } });

export const getFieldSheets = (
  payload: GetCustomerFieldSheetsPayload
): Promise<FieldSheetResponse> =>
  client('customers/field-sheets', { method: 'POST', body: { ...payload } });

export const takeCommitment = (payload: TakeCommitmentInput) =>
  client('/customer/take-commitment', { method: 'POST', body: { ...payload } });

export const getCommunicationDetails = (
  customerId: string
): Promise<CommunicationsDetailsResponse> =>
  client(`/customers/${customerId}/communication-details`, { method: 'GET' });

export const addressValidator = (payload: any) =>
  client(
    'customer/address/validate',
    {
      method: 'POST',
      body: {
        ...payload,
      },
    },
    appConfig.apiUrls.customer
  );

export const updatePhoneInstructions = (
  payload: CustomerPhoneInstructionPayload
) => client('/customers', { method: 'PUT', body: { ...payload } });

export const updateCustomer = (payload: any) =>
  client('customers', {
    method: 'PUT',
    body: {
      ...payload,
    },
  });

export const getCustomerStatesList = () =>
  client('references/entity?referenceCode=state_province', {
    method: 'GET',
  });
